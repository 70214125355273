import { useQuery } from "@tanstack/react-query";
import queryKeysConstants from "../../../../constants/queryKeys.constants";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { Profile } from "../../../../types/common.types";

async function getCurrentUser(): Promise<Profile> {
  const { data } = await axiosInstance.get(`/users/me`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });

  return data;
}

interface UseUser {
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  user: Profile | null;
}

export function useUser(): UseUser {
  const {
    data: user = null,
    isLoading,
    isFetching,
    isError,
  } = useQuery([queryKeysConstants.user], () => getCurrentUser(), {
    keepPreviousData: false,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return {
    isLoading,
    isFetching,
    isError,
    user,
  };
}
