import React, { useContext } from "react";
import { Toast } from "@shopify/polaris";
import AlertContext, { AlertContextType } from "../contexts/alert.context";

export function ToastAlert() {
  const { show, onHide, alertText, error } =
    useContext<AlertContextType>(AlertContext);

  return show ? (
    <Toast content={alertText} error={error} onDismiss={onHide} />
  ) : null;
}
