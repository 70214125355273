import { FormLayout, Modal } from "@shopify/polaris";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import AlertContext, {
  AlertContextType,
} from "../../../../../../contexts/alert.context";
import { Prompt, PromptRole } from "../../../../../../types/common.types";
import { useAddUpdatePrompt } from "../hooks/useAddUpdatePrompt";
import { queryClient } from "../../../../../../services/queryClient.service";
import queryKeysConstants from "../../../../../../constants/queryKeys.constants";
import TextFieldInput from "../../../../../../components/TextFieldInput";
import SelectInput from "../../../../../../components/SelectInput";

interface AddPromptModalProps {
  botId: string;
  prompt?: Prompt | null;
  active: boolean;
  setActive: (status: boolean) => void;
}

const schema = yup
  .object({
    role: yup.string().required(),
    content: yup.string().required(),
  })
  .required();

export default function AddPromptModal({
  botId,
  prompt,
  active,
  setActive,
}: AddPromptModalProps) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, reset } = useForm<Prompt>({
    resolver: yupResolver(schema),
    defaultValues: {
      role: PromptRole.USER,
    },
  });

  const addUpdatePrompt = useAddUpdatePrompt(botId, !!prompt);
  const handleClose = () => {
    setActive(false);
    setLoading(false);
  };

  const onHandleSubmit = async (prompt: Prompt) => {
    setLoading(true);

    try {
      await addUpdatePrompt(prompt);

      await queryClient.invalidateQueries([
        queryKeysConstants.bots,
        botId,
        "prompts",
      ]);

      handleClose();

      onShowToast("Prompt Added", false);
    } catch (e: any) {
      console.error(e.response.data.detail);

      onShowToast("Something went wrong", true);
    }

    setLoading(false);
  };

  useEffect(() => {
    reset({
      id: prompt?.id || null,
      role: prompt?.role || PromptRole.USER,
      content: prompt?.content || "",
    });
  }, [prompt]);

  return (
    <Modal
      open={active}
      onClose={handleClose}
      title={`${prompt ? "Edit" : "Add"} Prompt`}
      primaryAction={{
        content: "Save",
        disabled: loading,
        loading,
        onAction: handleSubmit(onHandleSubmit),
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <SelectInput
            control={control}
            name="role"
            label="Role"
            options={[
              {
                label: "User",
                value: "user",
              },
              {
                label: "System",
                value: "system",
              },
            ]}
          />
          <TextFieldInput
            control={control}
            name="content"
            label="Content"
            multiline={4}
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
