import React, { useContext, useEffect, useState } from "react";
import {
  Page,
  SkeletonPage,
  Layout,
  Card,
  SkeletonBodyText,
  TextContainer,
  SkeletonDisplayText,
  FormLayout,
} from "@shopify/polaris";

import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useBot } from "./hooks/useBot";
import PromptsTable from "./components/PromptsTable/PromptsTable";
import DeleteConfirmationDialog from "./components/DeleteConfirmationDialog";
import { useDeleteBot } from "./hooks/useDeleteBot";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";
import { Bot } from "../../../types/common.types";
import TextFieldInput from "../../../components/TextFieldInput";
import { useUpdateBot } from "./hooks/useUpdateBot";
import { queryClient } from "../../../services/queryClient.service";
import queryKeysConstants from "../../../constants/queryKeys.constants";

const schema = yup
  .object({
    id: yup.string().required(),
    name: yup.string().required(),
    prefix: yup.string().required(),
  })
  .required();

export default function BotPage() {
  const navigate = useNavigate();
  const { botId } = useParams();
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const { bot, isLoading, isError } = useBot(botId || "");

  const { control, reset, handleSubmit } = useForm<Bot>({
    resolver: yupResolver(schema),
  });

  const updateBot = useUpdateBot();
  const deleteBot = useDeleteBot(botId as string);

  if (isError) {
    navigate("/bots");
  }

  const handleOnBotDelete = async () => {
    setDeleteLoading(true);

    try {
      await deleteBot();
    } catch (e: any) {
      onShowToast(e.response.data.detail, true);
    }

    setDeleteLoading(false);

    navigate("/bots");
  };

  const handleUpdateBot = async (updatedBot: Bot) => {
    setUpdateLoading(true);

    try {
      await updateBot(updatedBot);

      await queryClient.invalidateQueries([queryKeysConstants.bots, botId]);

      onShowToast("Bot has been updated", false);
    } catch (e: any) {
      onShowToast(e.response.data.detail, true);
    }

    setUpdateLoading(false);
  };

  useEffect(() => {
    reset({
      id: bot?.id,
      name: bot?.name,
      prefix: bot?.prefix,
    });
  }, [bot]);

  if (isLoading)
    return (
      <SkeletonPage>
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <SkeletonBodyText />
            </Card>
            <Card sectioned>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            </Card>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    );

  return (
    <Page
      title={bot?.name}
      backAction={{ url: "/bots" }}
      primaryAction={{
        content: "Delete",
        destructive: true,
        onAction: () => setDeleteDialogOpen(true),
      }}
    >
      <Layout>
        <Layout.Section>
          <Card
            subdued
            primaryFooterAction={{
              content: "Save",
              loading: updateLoading,
              disabled: updateLoading,
              onAction: handleSubmit(handleUpdateBot),
            }}
          >
            <Card.Section title="Bot Config" subdued />
            <Card.Section>
              <FormLayout>
                <TextFieldInput control={control} name="name" label="Name" />
                <TextFieldInput
                  control={control}
                  name="prefix"
                  label="Prefix"
                />
              </FormLayout>
            </Card.Section>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <PromptsTable botId={botId as string} />
        </Layout.Section>
      </Layout>
      <DeleteConfirmationDialog
        name={bot?.name as string}
        open={deleteDialogOpen}
        loading={deleteLoading}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={handleOnBotDelete}
      />
    </Page>
  );
}
