import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "../../../../services/axios.service";
import { Config } from "../../../../types/common.types";
import queryKeysConstants from "../../../../constants/queryKeys.constants";
import { getAccessToken } from "../../../../services/auth.service";

async function getConfig(): Promise<Config> {
  const { data } = await axiosInstance.get(`/config/`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });

  return data;
}

interface UseConfig {
  isLoading: boolean;
  config: Config | null;
}

export function useConfig(): UseConfig {
  const { data, isLoading } = useQuery(
    [queryKeysConstants.config],
    () => getConfig(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isLoading,
    config: data || null,
  };
}
