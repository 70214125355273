import { Controller } from "react-hook-form";
import { TextField } from "@shopify/polaris";
import { getObjectValueWithDotNotation } from "../helpers/helpers";

interface Props {
  control: any;
  name: string;
  label: string;
  type?: any;
  suffix?: any;
  multiline?: any;
  min?: any;
  max?: any;
  disabled?: boolean;
}

export default function TextFieldInput({
  control,
  name,
  label,
  type = "text",
  suffix = null,
  multiline = null,
  min = null,
  max = null,
  disabled = false,
}: Props) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        const error = getObjectValueWithDotNotation(errors, name)?.message;

        return (
          <TextField
            type={type}
            suffix={suffix}
            multiline={multiline}
            error={error}
            label={label}
            autoComplete={name}
            onChange={onChange}
            value={value}
            min={min}
            max={max}
            disabled={disabled}
          />
        );
      }}
    />
  );
}
