import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";

async function deletePrompts(
  botId: string,
  promptIds: string[],
): Promise<void> {
  await axiosInstance.delete(
    `/bots/${botId}/prompts/?ids=${promptIds.join(",")}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    },
  );
}

export function useDeletePrompts(
  botId: string,
): UseMutateFunction<void, unknown, string[], unknown> {
  const { mutateAsync } = useMutation((promptIds: string[]) =>
    deletePrompts(botId, promptIds),
  );

  return mutateAsync;
}
