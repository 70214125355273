import {
  Card,
  IndexTable,
  Link,
  TextStyle,
  useIndexResourceState,
} from "@shopify/polaris";
import { useContext, useState } from "react";
import { SelectionType } from "@shopify/polaris/build/ts/latest/src/utilities/index-provider/types";
import { Prompt } from "../../../../../types/common.types";
import { usePrompts } from "./hooks/usePrompts";
import AddPromptModal from "./components/AddPromptModal";
import AlertContext, {
  AlertContextType,
} from "../../../../../contexts/alert.context";
import { queryClient } from "../../../../../services/queryClient.service";
import queryKeysConstants from "../../../../../constants/queryKeys.constants";
import { useDeletePrompts } from "./hooks/useDeletePrompts";

interface PromptsTableProps {
  botId: string;
}

export default function PromptsTable({ botId }: PromptsTableProps) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { isLoading, prompts } = usePrompts(botId);

  const [modalPrompt, setModalPrompt] = useState<Prompt | null>(null);
  const [addPromptModalActive, setAddPromptModalActive] = useState(false);

  const deletePrompts = useDeletePrompts(botId);

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(
      (prompts || []).map((prompt: Prompt) => ({
        ...prompt,
      })),
    );

  const handleDeletePrompts = async () => {
    onShowToast("Deleting Prompts", false);

    try {
      await deletePrompts(selectedResources);
      handleSelectionChange("all" as SelectionType, false);

      await queryClient.invalidateQueries([
        queryKeysConstants.bots,
        botId,
        "prompts",
      ]);
    } catch (e: any) {
      onShowToast(e.response.data.detail, true);
    }
  };

  const promotedBulkActions = [
    {
      content: "Delete Prompt(s)",
      onAction: handleDeletePrompts,
    },
  ];

  const promptsMarkup = prompts.map((prompt: Prompt, index: number) => (
    <IndexTable.Row
      id={prompt.id as string}
      key={prompt.id}
      position={index}
      selected={selectedResources.includes(prompt.id as string)}
      onClick={() => {
        setModalPrompt(prompt);
        setAddPromptModalActive(true);
      }}
    >
      <IndexTable.Cell>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link monochrome dataPrimaryLink>
          <TextStyle variation="strong">{prompt.role}</TextStyle>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>
        {`${prompt.content.substring(0, 100)}...`}
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <>
      <Card subdued>
        <Card.Section
          title="Prompts"
          actions={[
            {
              content: "Add",
              onAction: () => {
                setModalPrompt(null);
                setAddPromptModalActive(true);
              },
            },
          ]}
          subdued
        />
        <Card.Section flush>
          <IndexTable
            headings={[{ title: "Role" }, { title: "Content" }]}
            resourceName={{
              singular: "Prompt",
              plural: "Prompts",
            }}
            itemCount={prompts.length || 0}
            loading={isLoading}
            selectedItemsCount={
              allResourcesSelected ? "All" : selectedResources.length
            }
            onSelectionChange={handleSelectionChange}
            promotedBulkActions={promotedBulkActions}
          >
            {promptsMarkup}
          </IndexTable>
        </Card.Section>
      </Card>
      <AddPromptModal
        botId={botId}
        prompt={modalPrompt}
        active={addPromptModalActive}
        setActive={setAddPromptModalActive}
      />
    </>
  );
}
