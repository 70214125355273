import { Card, FormLayout, Layout, Page, Spinner } from "@shopify/polaris";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldInput from "../../../components/TextFieldInput";
import { Config } from "../../../types/common.types";
import { useConfig } from "./hooks/useConfig";
import { useUpdateConfig } from "./hooks/useUpdateConfig";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";

const schema = yup
  .object({
    openai_api_key: yup.string().required(),
    openai_model: yup.string().required(),
    openai_temperature: yup.number().required(),
  })
  .required();

export default function SettingsPage() {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, reset } = useForm<Config>({
    resolver: yupResolver(schema),
  });
  const { isLoading, config } = useConfig();
  const updateConfig = useUpdateConfig();

  const onHandleSubmit = async (settings: Config) => {
    setLoading(true);

    try {
      await updateConfig(settings);

      onShowToast("Config Updated", false);
    } catch (e: any) {
      onShowToast(e.response.data.detail, true);
    }

    setLoading(false);
  };

  useEffect(() => {
    reset({
      ...config,
    });
  }, [config]);

  if (isLoading)
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spinner />
      </div>
    );

  return (
    <Page title="Settings">
      <Layout>
        <Layout.Section>
          <Card
            primaryFooterAction={{
              content: "Save",
              disabled: loading,
              loading,
              onAction: handleSubmit(onHandleSubmit),
            }}
          >
            <Card.Section title="API Keys">
              <FormLayout>
                <TextFieldInput
                  control={control}
                  name="openai_api_key"
                  label="OpenAI API Key"
                />
                <TextFieldInput
                  control={control}
                  name="openai_model"
                  label="OpenAI Model"
                />
                <TextFieldInput
                  control={control}
                  name="openai_temperature"
                  label="OpenAI Temperature"
                  type="number"
                />
              </FormLayout>
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
