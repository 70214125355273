import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

export const app = initializeApp({
  apiKey: "AIzaSyBBT_7o3m96PSDEtQ0VU9aiTSz6Gl5vpaU",
  authDomain: "slack-bot-naonne.firebaseapp.com",
  projectId: "slack-bot-naonne",
  storageBucket: "slack-bot-naonne.appspot.com",
  messagingSenderId: "230966168128",
  appId: "1:230966168128:web:d4c1481886f7f7b28c2da5",
  measurementId: "G-X48TBQH1F5",
});

export const auth = getAuth(app);
