import React, { useEffect, useState } from "react";
import "@shopify/polaris/build/esm/styles.css";
import "@shopify/polaris-viz/build/esm/styles.css";
import { Spinner } from "@shopify/polaris";
import { Navigate, Route, Routes } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Protected, NotProtected } from "./components/AuthHelpers";
import Auth from "./layout/Auth";
import { useUser } from "./pages/Auth/Login/hooks/useUser";
import Dashboard from "./layout/Dashboard";

function App() {
  const [loading, setLoading] = useState(true);
  const { user, isLoading, isError } = useUser();
  const isLoggedIn = !!user && !isError;

  useEffect(() => {
    if (!isLoading) {
      setLoading(false);
    }
  }, [user, isLoading]);

  if (loading)
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spinner />
      </div>
    );

  return (
    <>
      <Routes>
        <Route
          path="/auth/*"
          element={
            <NotProtected isLoggedIn={isLoggedIn} redirectTo="/settings">
              <Auth />
            </NotProtected>
          }
        />
        <Route
          path="/*"
          element={
            <Protected isLoggedIn={isLoggedIn} redirectTo="/auth/login">
              <Dashboard />
            </Protected>
          }
        />
        <Route
          path="*"
          element={<Navigate to={isLoggedIn ? "/settings" : "/auth/login"} />}
        />
      </Routes>
      <ReactQueryDevtools />
    </>
  );
}

export default App;
