import { useQuery } from "@tanstack/react-query";
import queryKeysConstants from "../../../../constants/queryKeys.constants";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { Bot } from "../../../../types/common.types";

async function getBot(botId: string): Promise<Bot> {
  const { data } = await axiosInstance.get(`/bots/${botId}`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });

  return data;
}

interface UseBot {
  isLoading: boolean;
  isError: boolean;
  bot: Bot | null;
}

export function useBot(botId: string): UseBot {
  const {
    data: bot = null,
    isLoading,
    isError,
  } = useQuery([queryKeysConstants.bots, botId], () => getBot(botId), {
    refetchOnWindowFocus: false,
  });

  return {
    isLoading,
    isError,
    bot,
  };
}
