import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import { auth as firebaseAuth } from "./firebase.service";
import { User } from "../types/common.types";

export async function logInWithEmailAndPassword(user: User): Promise<string> {
  const authUser: any = await signInWithEmailAndPassword(
    firebaseAuth,
    user.email,
    user.password,
  );

  return authUser.user.accessToken;
}

export async function sendForgotPasswordEmail(email: string) {
  return sendPasswordResetEmail(firebaseAuth, email);
}

export async function logOut() {
  return signOut(firebaseAuth);
}

export function storeAccessToken(accessToken: string): void {
  localStorage.setItem("accessToken", accessToken);
}

export function removeAccessToken(): void {
  localStorage.removeItem("accessToken");
}

export async function getAccessToken(): Promise<string | undefined> {
  return firebaseAuth.currentUser?.getIdToken();
}
