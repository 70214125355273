import {
  Layout,
  Card,
  Page,
  Form,
  TextField,
  FormLayout,
  Button,
  Toast,
} from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { User } from "../../types/common.types";
import { sendForgotPasswordEmail } from "../../services/auth.service";

export default function ForgotPasswordPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [isError, setIsError] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [user, setUser] = useState<User>({
    email: "",
    password: "",
  });
  const handleChange = (value: any, name: string) => {
    setUser({ ...user, [name]: value });
  };

  const onHandleSubmit = async () => {
    setLoading(true);

    try {
      await sendForgotPasswordEmail(user.email);

      setIsError(false);
      setActive(true);
      setErrorMessage(
        "Please follow the instructions on your email to reset your password.",
      );

      setTimeout(() => {
        navigate("/auth", {
          replace: true,
        });
      }, 3000);
    } catch (err) {
      console.error(err);

      setIsError(true);
      setActive(true);
      setErrorMessage("Something went wrong.");
    }

    setLoading(false);
  };

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const toastMarkup = active ? (
    <Toast content={errorMessage} error={isError} onDismiss={toggleActive} />
  ) : null;

  return (
    <Page narrowWidth>
      <Layout>
        <Layout.Section>
          <Card title="Forgot Password?" sectioned>
            <Form onSubmit={onHandleSubmit}>
              <FormLayout>
                <TextField
                  label="Email"
                  type="email"
                  autoComplete="email"
                  value={user.email}
                  onChange={(value) => handleChange(value, "email")}
                />
                <Button primary submit loading={loading} disabled={loading}>
                  Send Instructions
                </Button>
              </FormLayout>
            </Form>
          </Card>
        </Layout.Section>
      </Layout>
      {toastMarkup}
    </Page>
  );
}
