import { Modal, FormLayout } from "@shopify/polaris";
import React, { useContext, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Bot } from "../../../../types/common.types";
import AlertContext, {
  AlertContextType,
} from "../../../../contexts/alert.context";
import TextFieldInput from "../../../../components/TextFieldInput";
import { useAddBot } from "../hooks/useAddBot";
import { queryClient } from "../../../../services/queryClient.service";
import queryKeysConstants from "../../../../constants/queryKeys.constants";

interface AddBotModalProps {
  active: boolean;
  setActive: (status: boolean) => void;
}

const schema = yup
  .object({
    id: yup.string().nullable(),
    name: yup.string().nullable(),
    prefix: yup.string().required(),
  })
  .required();

export default function AddBotModal({ active, setActive }: AddBotModalProps) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, reset } = useForm<Bot>({
    resolver: yupResolver(schema),
  });

  const addBot = useAddBot();

  const handleClose = () => {
    reset();
    setActive(false);
    setLoading(false);
  };

  const onHandleSubmit = async (bot: Bot) => {
    setLoading(true);

    try {
      console.log(bot);
      await addBot(bot);

      await queryClient.invalidateQueries([queryKeysConstants.bots]);

      handleClose();

      onShowToast(`Bot Added`, false);
    } catch (e: any) {
      onShowToast(e.response.data.detail, true);
    }

    setLoading(false);
  };

  return (
    <Modal
      open={active}
      onClose={handleClose}
      title="Add Bot"
      primaryAction={{
        content: "Save",
        disabled: loading,
        loading,
        onAction: handleSubmit(onHandleSubmit),
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <TextFieldInput control={control} label="Name" name="name" />
          <TextFieldInput control={control} label="Prefix" name="prefix" />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
