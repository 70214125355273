import { Frame, Navigation } from "@shopify/polaris";
import { ProfileMinor, SettingsMinor } from "@shopify/polaris-icons";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import React, { useState } from "react";

import logo from "../config/logo";
import Header from "../components/Header";
import { ToastAlert } from "../components/ToastAlert";
import SettingsPage from "../pages/Dashboard/Settings/SettingsPage";
import BotsPage from "../pages/Dashboard/Bots/BotsPage";
import BotPage from "../pages/Dashboard/Bots/BotPage";

export default function Dashboard() {
  const location = useLocation();
  const [showMobileNavigation, setShowMobileNavigation] = useState(false);

  const onMobileNavigationToggle = () => {
    setShowMobileNavigation(!showMobileNavigation);
  };

  const onHideMobileNavigation = () => {
    setShowMobileNavigation(false);
  };

  const navigationMarkup = (
    <Navigation onDismiss={onHideMobileNavigation} location={location.pathname}>
      <Navigation.Section
        items={[
          {
            url: "/bots",
            label: "Bots",
            icon: ProfileMinor,
          },
          {
            url: "/settings",
            label: "Settings",
            icon: SettingsMinor,
          },
        ]}
      />
    </Navigation>
  );

  return (
    <Frame
      logo={logo}
      topBar={<Header onNavigationToggle={onMobileNavigationToggle} />}
      navigation={navigationMarkup}
      showMobileNavigation={showMobileNavigation}
      onNavigationDismiss={onHideMobileNavigation}
    >
      <Routes>
        <Route path="/bots" element={<BotsPage />} />
        <Route path="/bots/:botId" element={<BotPage />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="*" element={<Navigate to="/bots" />} />
      </Routes>
      <ToastAlert />
    </Frame>
  );
}
