export enum PromptRole {
  SYSTEM = "system",
  USER = "user",
}

export interface Prompt {
  id: string | null;
  role: PromptRole;
  content: string;
}

export interface Config {
  openai_api_key: string;
  openai_model: string;
  openai_temperature: number;
  prompts: Prompt[];
}

export interface User {
  email: string;
  password: string;
}

export interface Profile {
  uid: string | null;
  name: string;
}

export interface Bot {
  id: string | null;
  name: string;
  prefix: string;
}
