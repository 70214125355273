import logoPng from "../static/images/logo_512.png";

const logo = {
  width: 40,
  topBarSource: logoPng,
  contextualSaveBarSource: logoPng,
  url: "/",
  accessibilityLabel: "InfinitiStar",
};

export default logo;
