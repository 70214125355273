import { useState, useMemo, createContext, ReactNode } from "react";

export interface AlertContextType {
  show: boolean;
  alertText: string;
  error: boolean;
  onShow: (text: string, error: boolean) => void;
  onHide: () => void;
}

const AlertContext = createContext<AlertContextType>({
  show: false,
  alertText: "",
  error: false,
  onHide: () => {},
  onShow: (text: string, error: boolean) => {},
});

interface Props {
  children?: ReactNode;
}

const AlertProvider = ({ children }: Props) => {
  const [show, setShow] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [error, setError] = useState(false);

  const value = useMemo(
    () => ({
      show,
      alertText,
      error,
      onShow: (text: string, error: boolean) => {
        setAlertText(text);
        setError(error);
        setShow(true);
      },
      onHide: () => setShow(false),
    }),
    [show, alertText, error],
  );

  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
};

export { AlertProvider };
export default AlertContext;
