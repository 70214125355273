import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { AppProvider, Spinner } from "@shopify/polaris";
import { QueryClientProvider } from "@tanstack/react-query";
import en from "@shopify/polaris/locales/en.json";
import { useAuthState } from "react-firebase-hooks/auth";
import App from "./App";
import "./index.css";
import { queryClient } from "./services/queryClient.service";
import { AlertProvider } from "./contexts/alert.context";
import { auth } from "./services/firebase.service";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

function Index() {
  const [, loading] = useAuthState(auth);

  if (loading)
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spinner />
      </div>
    );

  return (
    <BrowserRouter>
      <React.StrictMode>
        <AppProvider i18n={en}>
          <QueryClientProvider client={queryClient}>
            <AlertProvider>
              <App />
            </AlertProvider>
          </QueryClientProvider>
        </AppProvider>
      </React.StrictMode>
    </BrowserRouter>
  );
}

root.render(<Index />);
