import { useMutation } from "@tanstack/react-query";
import { UseMutateAsyncFunction } from "@tanstack/react-query/src/types";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { Bot } from "../../../../types/common.types";

async function updateBot(bot: Bot): Promise<Bot> {
  const { data } = await axiosInstance.put(`/bots/${bot.id}`, bot, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });

  return data;
}

export function useUpdateBot(): UseMutateAsyncFunction<Bot, unknown, Bot> {
  const { mutateAsync } = useMutation((bot: Bot) => updateBot(bot), {});

  return mutateAsync;
}
