import { useQuery } from "@tanstack/react-query";

import { Prompt } from "../../../../../../types/common.types";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";
import queryKeysConstants from "../../../../../../constants/queryKeys.constants";

async function getPrompts(botId: string): Promise<Prompt[]> {
  const { data } = await axiosInstance.get(`/bots/${botId}/prompts/`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  return data;
}

interface UsePrompts {
  isLoading: boolean;
  isFetching: boolean;
  prompts: Prompt[];
}

export function usePrompts(botId: string): UsePrompts {
  const { data, isLoading, isFetching } = useQuery(
    [queryKeysConstants.bots, botId, "prompts"],
    () => getPrompts(botId),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isLoading,
    isFetching,
    prompts: data || [],
  };
}
