import { Frame } from "@shopify/polaris";
import { Navigate, Route, Routes } from "react-router-dom";
import React from "react";
import logo from "../config/logo";
import Header from "../components/Header";
import Login from "../pages/Auth/Login/Login";
import ForgotPasswordPage from "../pages/Auth/ForgotPasswordPage";
import { ToastAlert } from "../components/ToastAlert";

export default function Auth() {
  return (
    <Frame logo={logo} topBar={<Header />}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="*" element={<Navigate to="/auth/login" />} />
      </Routes>
      <ToastAlert />
    </Frame>
  );
}
