import { useMutation } from "@tanstack/react-query";
import { UseMutateAsyncFunction } from "@tanstack/react-query/src/types";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";

async function deleteBot(botId: string): Promise<void> {
  const { data } = await axiosInstance.delete(`/bots/${botId}`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });

  return data;
}

export function useDeleteBot(
  botId: string,
): UseMutateAsyncFunction<void, unknown, void> {
  const { mutateAsync } = useMutation(() => deleteBot(botId), {});

  return mutateAsync;
}
