import { useQuery } from "@tanstack/react-query";
import queryKeysConstants from "../../../../constants/queryKeys.constants";
import { getAccessToken } from "../../../../services/auth.service";
import { axiosInstance } from "../../../../services/axios.service";
import { Bot } from "../../../../types/common.types";

async function getBots(): Promise<Bot[]> {
  const { data } = await axiosInstance.get(`/bots/`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  return data;
}

interface UseBots {
  isLoading: boolean;
  isFetching: boolean;
  bots: Bot[];
}

export function useBots(): UseBots {
  const { data, isLoading, isFetching } = useQuery(
    [queryKeysConstants.bots],
    () => getBots(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isLoading,
    isFetching,
    bots: data || [],
  };
}
