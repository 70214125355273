import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { Prompt } from "../../../../../../types/common.types";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";

async function addPrompt(botId: string, prompt: Prompt): Promise<void> {
  await axiosInstance.post(`/bots/${botId}/prompts/`, prompt, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

async function updatePrompt(botId: string, prompt: Prompt): Promise<void> {
  await axiosInstance.put(`/bots/${botId}/prompts/${prompt.id}`, prompt, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

export function useAddUpdatePrompt(
  botId: string,
  update: boolean,
): UseMutateAsyncFunction<void, unknown, Prompt, unknown> {
  const { mutateAsync } = useMutation((prompt: Prompt) =>
    update ? updatePrompt(botId, prompt) : addPrompt(botId, prompt),
  );

  return mutateAsync;
}
