import { Card, IndexTable, Link, Page, TextStyle } from "@shopify/polaris";
import { useState } from "react";
import { Bot } from "../../../types/common.types";
import AddBotModal from "./components/AddBotModal";
import { useBots } from "./hooks/useBots";

export default function BotsPage() {
  const [addBotModalActive, setAddBotModalActive] = useState(false);

  const { isFetching, bots } = useBots();

  const rowMarkup = bots.map((bot: Bot, index) => (
    <IndexTable.Row id={bot.id as string} key={bot.id} position={index}>
      <IndexTable.Cell>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link dataPrimaryLink url={`/bots/${bot.id}`}>
          <TextStyle variation="strong">{bot.name}</TextStyle>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>{bot.prefix}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Page>
      <Card>
        <Card.Section
          title="Bots"
          subdued
          actions={[
            {
              content: "Add",
              onAction: () => {
                setAddBotModalActive(true);
              },
            },
          ]}
        />
        <Card.Section flush>
          <IndexTable
            resourceName={{
              singular: "Bot",
              plural: "Bots",
            }}
            itemCount={bots.length}
            headings={[{ title: "Bot Name" }, { title: "Bot Prefix" }]}
            loading={isFetching}
            selectable={false}
          >
            {rowMarkup}
          </IndexTable>
        </Card.Section>
        <AddBotModal
          active={addBotModalActive}
          setActive={setAddBotModalActive}
        />
      </Card>
    </Page>
  );
}
